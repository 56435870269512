import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const OperatorsAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nome"),
		telephone: yup.string().required().label("Telefono"),
		password: yup.string().min(8).required().label("Password"),
		confirm_password: yup.string().required().label("Confirm Password").oneOf([yup.ref('password')], "Your passwords do not match"),
		email: yup.string().email().required().label("Email"),
		photo: yup.string().nullable().label("Foto Attestati"),
		working_experience: yup.string().nullable().label("Esperienza Lavorativa"),
		divinatory_tools: yup.string().nullable().label("Strumento Divinatori"),
		time_preference: yup.string().nullable().label("Preferenza Oraria"),
		iban: yup.string().nullable().label("Iban"),
		code: yup.string().nullable().label("Codice"),
		presentation: yup.string().nullable().label("Presentazione"),
		presentation_active: yup.string().nullable().label("Presentazione Attiva"),
		is_vat: yup.string().nullable().label("Partita IVA"),
		recruitment_notes: yup.string().nullable().label("Note Reclutamento"),
		hourly_availability: yup.string().nullable().label("Disponibilità Oraria"),
		work_hour: yup.number().nullable().label("Ore Lavoro"),
		device: yup.string().nullable().label("Dispositivi")
	});
	
	//form default values
	const formDefaultValues = {
		name: '', 
		telephone: '', 
		password: '', 
		confirm_password: '', 
		email: '', 
		photo: '', 
		working_experience: "NULL", 
		divinatory_tools: "NULL", 
		time_preference: "NULL", 
		iban: "NULL", 
		code: "NULL", 
		presentation: "NULL", 
		presentation_active: '', 
		is_vat: '', 
		recruitment_notes: '', 
		hourly_availability: '', 
		work_hour: '', 
		device: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/operators`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="OperatorsAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Aggiungere nuova"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="name"  onChange={formik.handleChange}  value={formik.values.name}   label="Nome" type="text" placeholder="accedere Nome"        className={inputClassName(formik?.errors?.name)} />
                                                <ErrorMessage name="name" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Telefono *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="telephone"  onChange={formik.handleChange}  value={formik.values.telephone}   label="Telefono" type="text" placeholder="accedere Telefono"        className={inputClassName(formik?.errors?.telephone)} />
                                                <ErrorMessage name="telephone" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Password *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="password" value={formik.values.password} onChange={formik.handleChange} label="Password" placeholder="accedere Password"  inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.password)} />
                                                <ErrorMessage name="password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Confirm Password *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="confirm_password" id="confirm_password" className={inputClassName(formik?.errors?.comfirm_password)} inputClassName="w-full" feedback={false} toggleMask  value={formik.values.confirm_password} onChange={formik.handleChange} label="conferma password" placeholder="conferma password"  />
                                                <ErrorMessage name="confirm_password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Email *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.email} apiPath="components_data/operators_email_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="email" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="accedere Email"        className={inputClassName(formik?.errors?.email)} />
                                                <ErrorMessage name="email" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">Non disponibile</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Foto Attestati 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.photo)}>
                                                    <Uploader name="photo" showUploadedFiles value={formik.values.photo} uploadPath="fileuploader/upload/photo" onChange={(paths) => formik.setFieldValue('photo', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="photo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Esperienza Lavorativa 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="working_experience"  onChange={formik.handleChange}  value={formik.values.working_experience}   label="Esperienza Lavorativa" type="text" placeholder="accedere Esperienza Lavorativa"        className={inputClassName(formik?.errors?.working_experience)} />
                                                <ErrorMessage name="working_experience" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Strumento Divinatori 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="divinatory_tools"  onChange={formik.handleChange}  value={formik.values.divinatory_tools}   label="Strumento Divinatori" type="text" placeholder="accedere Strumento Divinatori"        className={inputClassName(formik?.errors?.divinatory_tools)} />
                                                <ErrorMessage name="divinatory_tools" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Preferenza Oraria 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="time_preference" value={formik.values.time_preference} onChange={formik.handleChange} showButtonBar className={inputClassName(formik?.errors?.time_preference)}     showTime timeOnly     />
                                                <ErrorMessage name="time_preference" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Iban 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="iban"  onChange={formik.handleChange}  value={formik.values.iban}   label="Iban" type="text" placeholder="accedere Iban"        className={inputClassName(formik?.errors?.iban)} />
                                                <ErrorMessage name="iban" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Codice 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="code"  onChange={formik.handleChange}  value={formik.values.code}   label="Codice" type="text" placeholder="accedere Codice"        className={inputClassName(formik?.errors?.code)} />
                                                <ErrorMessage name="code" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Presentazione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="presentation"  onChange={formik.handleChange}  value={formik.values.presentation}   label="Presentazione" type="text" placeholder="accedere Presentazione"        className={inputClassName(formik?.errors?.presentation)} />
                                                <ErrorMessage name="presentation" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Presentazione Attiva 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className="flex flex-wrap">
                                                    {
                                                    app.menus.used.map((option) => {
                                                    return (
                                                    <div key={option.value} className="field-radiobutton  mx-3">
                                                        <RadioButton inputId={option.value} name="presentation_active" value={option.value} onChange={formik.handleChange}  checked={formik.values.presentation_active === option.value} className={inputClassName(formik?.errors?.presentation_active, '')} />
                                                        <label htmlFor={option.value}>{option.label}</label>
                                                    </div>
                                                    )
                                                    })
                                                    }
                                                </div>
                                                <ErrorMessage name="presentation_active" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Partita IVA 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className="flex flex-wrap">
                                                    {
                                                    app.menus.used.map((option) => {
                                                    return (
                                                    <div key={option.value} className="field-radiobutton  mx-3">
                                                        <RadioButton inputId={option.value} name="is_vat" value={option.value} onChange={formik.handleChange}  checked={formik.values.is_vat === option.value} className={inputClassName(formik?.errors?.is_vat, '')} />
                                                        <label htmlFor={option.value}>{option.label}</label>
                                                    </div>
                                                    )
                                                    })
                                                    }
                                                </div>
                                                <ErrorMessage name="is_vat" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Note Reclutamento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="recruitment_notes"  className={inputClassName(formik?.errors?.recruitment_notes)}   value={formik.values.recruitment_notes} placeholder="accedere Note Reclutamento" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="recruitment_notes" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Disponibilità Oraria 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="hourly_availability"  onChange={formik.handleChange}  value={formik.values.hourly_availability}   label="Disponibilità Oraria" type="text" placeholder="accedere Disponibilità Oraria"        className={inputClassName(formik?.errors?.hourly_availability)} />
                                                <ErrorMessage name="hourly_availability" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ore Lavoro 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="work_hour"  onChange={formik.handleChange}  value={formik.values.work_hour}   label="Ore Lavoro" type="number" placeholder="accedere Ore Lavoro"  min={0}  step="any"    className={inputClassName(formik?.errors?.work_hour)} />
                                                <ErrorMessage name="work_hour" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Dispositivi 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="device"  onChange={formik.handleChange}  value={formik.values.device}   label="Dispositivi" type="text" placeholder="accedere Dispositivi"        className={inputClassName(formik?.errors?.device)} />
                                                <ErrorMessage name="device" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Sottoscrivi" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
OperatorsAddPage.defaultProps = {
	primaryKey: 'id_operator',
	pageName: 'operators',
	apiPath: 'operators/add',
	routeName: 'operatorsadd',
	submitButtonLabel: "Sottoscrivi",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Crea registro",
	msgAfterSave: "Record aggiunto con successo",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default OperatorsAddPage;
