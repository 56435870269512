import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { CanView } from 'components/Can';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"

import useListPage from 'hooks/useListPage';
import Calendar from 'components/Calendar';
import { useEffect, useState } from 'react';
import axios from 'axios';
const AppointmentsAgendaPage = (props) => {
	const auth = useAuth();
	const app = useApp();
	const [myEvents, setMyEvents] = useState();
	let body = JSON.stringify({
		"apikey": "qLr4AFtXG0z9rKk68gEyH0qdZI2jzTj16kSfsEX4eYBrknZ6HFrDMUdogQLQEDeTJ6tDmcviarsp09FXvkI8EMQiHhegu8DiRKELHtU5qt5RW6ck1XQLRqeHy5c038gBqIAlXDjur7LHT072iZgm260yL5Ml4zxDKcFZMZCk9tmznOuZ0FeKsPJb2X3gSPLlIc0KXv81",
		"role": auth.user.user_role_id,
		"user_id": auth.userId

	});
	useEffect(() => {
		axios.post('https://cartomanzia.devgo.it/apim/selectAppointments.php', body).then((response) => {
			/*setMyEvents([{
				title: ,
				start: new Date('2024-03-08T15:00:00+01:00'),
				color: "#03fc45"
			},
		
		])*/
			// console.log(response);
			var events = [];
			response.data.forEach(element => {
				events.push({
					title: element['appointment_title'] + " - " + element['operator_name'] + " - " + element['client_name'] + " " + element['client_surname'],
					start: new Date(element['start_time']),
					end: new Date(element['end_time']),
					color: element['operator_color'],
					description: "descrizione",
					id: element['id_appointment']
				})
			});
			// console.log(events)
			setMyEvents(events)

		})
	}, []);


	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;
	function ActionButton(data) {
		const items = [
			{
				label: "View",
				command: (event) => { app.navigate(`/appointments/view/${data.id_appointment}`) },
				icon: "pi pi-eye",
				visible: () => auth.canView('appointments/view')
			},
			{
				label: "Edit",
				command: (event) => { app.navigate(`/appointments/edit/${data.id_appointment}`) },
				icon: "pi pi-pencil",
				visible: () => auth.canView('appointments/edit')
			},
			{
				label: "Delete",
				command: (event) => { deleteItem(data.id_appointment) },
				icon: "pi pi-trash",
				visible: () => auth.canView('appointments/delete')
			}
		]
			.filter((item) => {
				if (item.visible) {
					return item.visible()
				}
				return true;
			});
		return (<Menubar className="p-0 " model={items} />);
	}
	function IdAppointmentTemplate(data) {
		if (data) {
			return (
				<Link to={`/appointments/view/${data.id_appointment}`}> {data.id_appointment}</Link>
			);
		}
	}
	function PageLoading() {
		if (loading) {
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{ width: '30px', height: '30px' }} /> </div>
						<div className="font-bold text-lg">Caricamento in corso...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nessun record trovato
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Elimina selezionato" />
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
			const pagerReportTemplate = {
				layout: pagination.layout,
				CurrentPageReport: (options) => {
					return (
						<>
							<span className="text-sm text-gray-500 px-2">Record <b>{recordsPosition} di {options.totalRecords}</b></span>
						</>
					);
				}
			}
			return (
				<div className="flex-grow-1">
					<Paginator first={firstRow} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} template={pagerReportTemplate} />
				</div>
			)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<CanView pagePath="appointments/delete">
					<MultiDelete />
				</CanView>
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs() {
		if (props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}

	const handleEventClick = (info) => {
		window.location.href = `/appointments/view/${info.event.id}`
	}
	function renderEventContent(eventInfo) {
		return (
			<>
				<b>{eventInfo.timeText}</b>
				<i>{eventInfo.event.title}</i>
			</>
		)
	}

	if (apiRequestError) {
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
		<main id="AppointmentsAgendaPage" className="main-page">
			{(props.showHeader) &&
				<section className="page-section mb-3" >
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col " >
								<Title title="Appuntamenti" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>
							<div className="col-fixed" >

								<CanView pagePath="/appointments/add">
									<Link to={`/appointments/add`}>
										<Button label="Aggiungere nuova" icon="pi pi-plus" type="button" className="p-button w-full bg-primary " />
									</Link>
								</CanView>

							</div>
							{/* 		<div className="col-12 md:col-3 " >
								<span className="p-input-icon-left w-full">
									<i className="pi pi-search" />
									<InputText placeholder="Ricerca" className="w-full" value={filters.search.value} onChange={(e) => setFilterValue('search', e.target.value)} />
								</span>
							</div> */}



						</div>
					</div>
				</section>
			}
			<section className="page-section " >
				<div className="container-fluid">
					<div className="grid ">
						<div className="col comp-grid" >
							<Calendar
								myEvents={myEvents}
								handleEventClick={handleEventClick}

							/>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
AppointmentsAgendaPage.defaultProps = {
	primaryKey: 'id_appointment',
	pageName: 'appointments',
	apiPath: 'appointments/agenda',
	routeName: 'appointmentsagenda',
	msgBeforeDelete: "Sei sicuro di voler cancellare questo record?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record cancellato con successo",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default AppointmentsAgendaPage;

