import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { CanView } from 'components/Can';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import useListPage from 'hooks/useListPage';

const AppointmentsAgendaoPage = (props) => {
	const auth = useAuth();
	const app = useApp();
	app.navigate(`/appointments/agenda`);


}
AppointmentsAgendaoPage.defaultProps = {
	primaryKey: 'id_appointment',
	pageName: 'appointments',
	apiPath: 'appointments/agendao',
	routeName: 'appointmentsagendao',
	msgBeforeDelete: "Sei sicuro di voler eliminare questo elemento?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record cancellato",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default AppointmentsAgendaoPage;
