import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { CanView } from 'components/Can';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import useListPage from 'hooks/useListPage';
const SupportListPage = (props) => {
	const auth = useAuth();
	const app = useApp();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, apiRequestError, getPageBreadCrumbs, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;
	function ActionButton(data) {
		const items = [
			{
				label: "Edit",
				command: (event) => { app.navigate(`/support/edit/${data.id}`) },
				icon: "pi pi-pencil",
				visible: () => auth.canView('support/edit')
			},
			{
				label: "Delete",
				command: (event) => { deleteItem(data.id) },
				icon: "pi pi-trash",
				visible: () => auth.canView('support/delete')
			}
		]
			.filter((item) => {
				if (item.visible) {
					return item.visible()
				}
				return true;
			});
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function PageLoading() {
		if (loading) {
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{ width: '30px', height: '30px' }} /> </div>
						<div className="font-bold text-lg">Caricamento in corso...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					No record found
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Elimina selezionato" />
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
			const pagerReportTemplate = {
				layout: pagination.layout,
				CurrentPageReport: (options) => {
					return (
						<>
							<span className="text-sm text-gray-500 px-2">Record <b>{recordsPosition} di {options.totalRecords}</b></span>
						</>
					);
				}
			}
			return (
				<div className="flex-grow-1">
					<Paginator first={firstRow} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} template={pagerReportTemplate} />
				</div>
			)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<CanView pagePath="support/delete">
					<MultiDelete />
				</CanView>
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs() {
		if (props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if (apiRequestError) {
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
		<main id="SupportListPage" className="main-page">
			{(props.showHeader) &&
				<section className="page-section mb-3" >
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col " >
								<Title title="Supporto" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>
							<div className="col-fixed " >
								<CanView pagePath="/support/add">
									<Link to={`/support/add`}>
										<Button label="Aggiungere nuova" icon="pi pi-plus" type="button" className="p-button w-full bg-primary " />
									</Link>
								</CanView>
							</div>
						</div>
					</div>
				</section>
			}
			<section className="page-section " >
				<div className="container-fluid">
					<div className="grid ">
						<div className="col comp-grid" >
							<FilterTags filterController={filterController} />
							<div >
								<PageBreadcrumbs />
								<div className="page-records">
									<div className="grid ">
										{records.map((data, index) =>
											<div className="col-12" key={index}>
												<div className="card card shadow-none p-3 surface-100 ">
													{/*PageComponentStart*/}
													<div className="flex align-items-center">
														<div className=" flex flex-column gap-1">
															<div className="font-bold">Argomento</div>
															<div className="font-bold text-500">{data.title}</div>
														</div>
													</div>
													<hr className="my-2" />
													<div className="flex align-items-center">
														<div className=" flex flex-column gap-1">
															<div className="font-bold">Descrizione</div>
															<div className="font-bold text-500">{data.description}</div>
														</div>
													</div>
													<hr className="my-2" />
													<div className="flex justify-content-end">
														{ActionButton(data)}
													</div>
													{/*PageComponentEnd*/}
												</div>
											</div>
										)}
									</div>
								</div>
								<EmptyRecordMessage />
								<PageFooter />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section style={{

				overflow: 'hidden',
				position: 'fixed',
				bottom: 0,	
				left: 0,			
				width: '100%',
				height: '100px',
				backgroundColor: '#e5e7eb',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				
					
								<span className="p-input-icon-right" style={{width: '50%'}}>
					<InputText placeholder="Di cosa hai bisogno?" className="w-full p-inputtext-lg" value={filters.search.value} onChange={(e) => setFilterValue('search', e.target.value)} />
									<i className="pi pi-send" />
								</span>
							
						
				
			</section>
		</main>
	);
}
SupportListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'support',
	apiPath: 'support/index',
	routeName: 'supportlist',
	msgBeforeDelete: "Are you sure you want to delete this record?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record deleted successfully",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default SupportListPage;
