import { useState } from 'react';

export default function HomePage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="HomePage" className="main-page">
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
