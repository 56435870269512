import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const OperatorsEditcPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nome"),
		surname: yup.string().required().label("Cognome"),
		status_operator: yup.string().nullable().label("Stato Operatore"),
		user_role_id: yup.string().nullable().label("Ruolo"),
		password: yup.string().min(8).required().label("Password"),
		confirm_password: yup.string().required().label("Confirm Password").oneOf([yup.ref('password')], "Your passwords do not match"),
		photo: yup.string().nullable().label("Photo"),
		working_experience: yup.string().nullable().label("Working Experience"),
		divinatory_tools: yup.string().nullable().label("Divinatory Tools"),
		time_preference: yup.string().nullable().label("Time Preference"),
		iban: yup.string().nullable().label("Iban"),
		code: yup.string().nullable().label("Code"),
		presentation: yup.string().nullable().label("Presentation"),
		presentation_active: yup.number().nullable().label("Presentation Active"),
		is_vat: yup.number().required().label("Is Vat"),
		recruitment_notes: yup.string().nullable().label("Recruitment Notes"),
		hourly_availability: yup.string().nullable().label("Hourly Availability"),
		work_hour: yup.number().nullable().label("Work Hour"),
		device: yup.string().nullable().label("Device")
	});
	// form default values
	const formDefaultValues = {
		name: '', 
		surname: '', 
		status_operator: '', 
		user_role_id: '', 
		password: '', 
		confirm_password: '', 
		photo: '', 
		working_experience: "NULL", 
		divinatory_tools: "NULL", 
		time_preference: "NULL", 
		iban: "NULL", 
		code: "NULL", 
		presentation: "NULL", 
		presentation_active: "0", 
		is_vat: '', 
		recruitment_notes: '', 
		hourly_availability: "NULL", 
		work_hour: "NULL", 
		device: "NULL", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`//operators`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="OperatorsEditcPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.name} apiPath="components_data/operators_name_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="name" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.name}   label="Nome" type="text" placeholder="accedere Nome"        className={inputClassName(formik?.errors?.name)} />
                                                <ErrorMessage name="name" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">Non disponibile</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cognome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="surname"  onChange={formik.handleChange}  value={formik.values.surname}   label="Cognome" type="text" placeholder="accedere Cognome"        className={inputClassName(formik?.errors?.surname)} />
                                                <ErrorMessage name="surname" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Stato Operatore 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/status_operator_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="status_operator"     optionLabel="label" optionValue="value" value={formik.values.status_operator} onChange={formik.handleChange} options={response} label="Stato Operatore"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.status_operator)}   />
                                                    <ErrorMessage name="status_operator" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ruolo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/user_role_id_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="user_role_id"     optionLabel="label" optionValue="value" value={formik.values.user_role_id} onChange={formik.handleChange} options={response} label="Ruolo"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.user_role_id)}   />
                                                    <ErrorMessage name="user_role_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Password *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="password" value={formik.values.password} onChange={formik.handleChange} label="Password" placeholder="accedere Password"  inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.password)} />
                                                <ErrorMessage name="password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Confirm Password *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="confirm_password" id="confirm_password" className={inputClassName(formik?.errors?.comfirm_password)} inputClassName="w-full" feedback={false} toggleMask  value={formik.values.confirm_password} onChange={formik.handleChange} label="conferma password" placeholder="conferma password"  />
                                                <ErrorMessage name="confirm_password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Photo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.photo)}>
                                                    <Uploader name="photo" showUploadedFiles value={formik.values.photo} uploadPath="fileuploader/upload/photo" onChange={(paths) => formik.setFieldValue('photo', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="photo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Working Experience 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="working_experience"  onChange={formik.handleChange}  value={formik.values.working_experience}   label="Working Experience" type="text" placeholder="accedere Working Experience"        className={inputClassName(formik?.errors?.working_experience)} />
                                                <ErrorMessage name="working_experience" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Divinatory Tools 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="divinatory_tools"  onChange={formik.handleChange}  value={formik.values.divinatory_tools}   label="Divinatory Tools" type="text" placeholder="accedere Divinatory Tools"        className={inputClassName(formik?.errors?.divinatory_tools)} />
                                                <ErrorMessage name="divinatory_tools" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Time Preference 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="time_preference" value={formik.values.time_preference} onChange={formik.handleChange} showButtonBar className={inputClassName(formik?.errors?.time_preference)}     showTime timeOnly     />
                                                <ErrorMessage name="time_preference" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Iban 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="iban"  onChange={formik.handleChange}  value={formik.values.iban}   label="Iban" type="text" placeholder="accedere Iban"        className={inputClassName(formik?.errors?.iban)} />
                                                <ErrorMessage name="iban" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Code 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="code"  onChange={formik.handleChange}  value={formik.values.code}   label="Code" type="text" placeholder="accedere Code"        className={inputClassName(formik?.errors?.code)} />
                                                <ErrorMessage name="code" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Presentation 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="presentation"  onChange={formik.handleChange}  value={formik.values.presentation}   label="Presentation" type="text" placeholder="accedere Presentation"        className={inputClassName(formik?.errors?.presentation)} />
                                                <ErrorMessage name="presentation" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Presentation Active 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="presentation_active"  onChange={formik.handleChange}  value={formik.values.presentation_active}   label="Presentation Active" type="number" placeholder="accedere Presentation Active"  min={0}  step="any"    className={inputClassName(formik?.errors?.presentation_active)} />
                                                <ErrorMessage name="presentation_active" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Is Vat *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="is_vat"  onChange={formik.handleChange}  value={formik.values.is_vat}   label="Is Vat" type="number" placeholder="accedere Is Vat"  min={0}  step="any"    className={inputClassName(formik?.errors?.is_vat)} />
                                                <ErrorMessage name="is_vat" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Recruitment Notes 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="recruitment_notes"  className={inputClassName(formik?.errors?.recruitment_notes)}   value={formik.values.recruitment_notes} placeholder="accedere Recruitment Notes" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="recruitment_notes" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Hourly Availability 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="hourly_availability"  onChange={formik.handleChange}  value={formik.values.hourly_availability}   label="Hourly Availability" type="text" placeholder="accedere Hourly Availability"        className={inputClassName(formik?.errors?.hourly_availability)} />
                                                <ErrorMessage name="hourly_availability" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Work Hour 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="work_hour"  onChange={formik.handleChange}  value={formik.values.work_hour}   label="Work Hour" type="number" placeholder="accedere Work Hour"  min={0}  step="any"    className={inputClassName(formik?.errors?.work_hour)} />
                                                <ErrorMessage name="work_hour" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Device 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="device"  onChange={formik.handleChange}  value={formik.values.device}   label="Device" type="text" placeholder="accedere Device"        className={inputClassName(formik?.errors?.device)} />
                                                <ErrorMessage name="device" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiornare" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                    <div className="col comp-grid" >
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
OperatorsEditcPage.defaultProps = {
	primaryKey: 'id_operator',
	pageName: 'operators',
	apiPath: 'operators/editc',
	routeName: 'operatorseditc',
	submitButtonLabel: "Aggiornare",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default OperatorsEditcPage;
