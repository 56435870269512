import { useState } from 'react';
import { RecordCount } from 'components/RecordCount';
import { Title } from 'components/Title';

export default function AmministratorePage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="AmministratorePage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <Title title="Dashboard Amministratore"   titleClass="text-lg font-bold text-primary" subTitleClass="text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-4 comp-grid" >
                <RecordCount title="Operatori Attivi"  icon="pi pi-user-plus" apiPath="components_data/getcount_operatoriattivi" link={`/operators`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" valuePrefix="N."  />
            </div>
            <div className="col-4 comp-grid" >
                <RecordCount title="Operatori Lead"  icon="pi pi-user-edit" apiPath="components_data/getcount_operatorilead" link={`/operators`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" valuePrefix="N."  />
            </div>
            <div className="col-4 comp-grid" >
                <RecordCount title="Operatori Cessati"  icon="pi pi-user-minus" apiPath="components_data/getcount_operatoricessati" link={`/operators`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100" valuePrefix="N."  />
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
