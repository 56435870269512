import React, { Component, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick

// import './FullCalendar.css'

export default function Calendar({ myEvents , handleEventClick}) {

    const calendarComponentRef = useRef();
    /* // calendarComponentRef = React.createRef()
    state = {
        calendarWeekends: true,
           calendarEvents: [ // initial event data
               // { title: 'Event Now', start: new Date() },
               {
                   title: 'Operatore 1',
                   //    start: new Date('2024-03-08T15:00:00+01:00'),
                   start: new Date(),
                   color: "#03fc45"
               },
               {
                   title: 'Operatore 2',
                   //    start: new Date('2024-03-08T15:00:00+01:00'),
                   start: new Date(),
                   color: "#FF2D00"
               },
   
           ] 
    }*/


    const handleDateClick = (arg) => {
        if (window.confirm('Ciao you like to add a meal to ' + arg.dateStr + ' ?')) {
            // console.log(arg);
            /*      this.setState({  // add new event data
                     calendarEvents: this.state.calendarEvents.concat({ // creates a new array
                         title: 'New Event',
                         start: arg.date,
                         allDay: arg.allDay
                     })
                 }) */
            // console.log("bella" + arg);
            // app.navigate(`/roles/view/${arg.}`) 
        }
    }

 
        /*alert('Event: ' + info.event.id);
  
  
      /*    alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
          alert('View: ' + info.view.type); */

        // change the border color just for fun
        /*         info.el.style.borderColor = 'red'; */
    

    // console.log(myEvents);
    return (
        <div className='demo-app'>
            <div className='demo-app-calendar'>
                <FullCalendar
                    initialView="dayGridMonth"
                    firstDay='1'
                    locale='it'
                    nowIndicator={true}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    ref={calendarComponentRef}
                    weekends={true}
                    events={myEvents}
                    // dateClick={}
                    eventClick={handleEventClick}
                />
            </div>
        </div>
    )



}
