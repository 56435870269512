/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/appointments/agendao",
    "label": "Agenda",
    "icon": "pi pi-calendar",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/home",
    "label": "Reclutamento",
    "icon": "pi pi-user",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/appointments/agendareclutamento",
        "label": "Agenda Reclutamento",
        "icon": "pi pi-calendar-plus",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/operators/status_operator/1",
        "label": "Candidati",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/operators/contacted",
        "label": "Contattati",
        "icon": "pi pi-phone",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/operators/status_operator/2",
        "label": "Attivi",
        "icon": "pi pi-user-plus",
        "iconcolor": "",
        "target": "",
      }
    ]
  },
  {
    "to": "/operators",
    "label": "Gestione Operatori",
    "icon": "pi pi-user",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/operators/status_operator/2",
        "label": "Attivi",
        "icon": "pi pi-user-plus",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/appointments/agenda",
        "label": "Agenda",
        "icon": "pi pi-calendar",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/bonus",
        "label": "Bonus",
        "icon": "pi pi-angle-double-up",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/operators/status_operator/3",
        "label": "Cessati",
        "icon": "pi pi-user-minus",
        "iconcolor": "",
        "target": "",
      }
    ]
  },
  {
    "to": "/home",
    "label": "Controllo Qualità",
    "icon": "pi pi-check-circle",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/operators/status_operator/2",
        "label": "Attivi",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/operators/status_operator/3",
        "label": "Cessati",
        "icon": "pi pi-user-minus",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/qualitycheck",
        "label": "Note Qualità",
        "icon": "pi pi-check",
        "iconcolor": "",
        "target": "",
      }
    ]
  },
  {
    "to": "/clients",
    "label": "Clienti",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/support",
    "label": "Supporto",
    "icon": "pi pi-comments",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/files/carta_id",
    "label": "Contabilità",
    "icon": "pi pi-euro",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/files/carta_id",
        "label": "Commercialista",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        "items": [
          {
            "to": "files/carta_id",
            "label": "Carta Identità",
            "icon": "pi pi-id-card",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "files/tessera",
            "label": "Codice Fiscale",
            "icon": "pi pi-credit-card",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "files/attribuzione",
            "label": "Attribuzione partita iva",
            "icon": "pi pi-align-justify",
            "iconcolor": "",
            "target": "",
          }
        ]
      },
      {
        "to": "/files/notula",
        "label": "Compensi",
        "icon": "pi pi-money-bill",
        "iconcolor": "",
        "target": "",
        "items": [
          {
            "to": "files/notula",
            "label": "Notule",
            "icon": "pi pi-list",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "files/fatture",
            "label": "Fatture di cortesia",
            "icon": "pi pi-server",
            "iconcolor": "",
            "target": "",
          }
        ]
      }
    ]
  },
  {
    "to": "/home",
    "label": "Impostazioni",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/operatorstatuses",
        "label": "Stati Operatori",
        "icon": "pi pi-comment",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/alias",
        "label": "Alias",
        "icon": "pi pi-id-card",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/filetypes",
        "label": "Tipo File",
        "icon": "pi pi-paperclip",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/operators/user_role_id/3",
        "label": "Collaboratori",
        "icon": "pi pi-user-plus",
        "iconcolor": "",
        "target": "",
      }
    ]
  }
],
	gender: [    
{value: "Uomo", label: "Uomo"},
	{value: "Donna", label: "Donna"},
	{value: "Altro", label: "Altro"}
    ],
	used: [    
{value: "0", label: "No"},
	{value: "1", label: "Si"}
    ],
	valueType: [    
{value: "1", label: "Percentuale"},
	{value: "2", label: "Euro"}
    ],
	gender2: [{"label":"Uomo","value":"Uomo"},{"label":"Donna","value":"Donna"},{"label":"Altro","value":"Altro"}],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}