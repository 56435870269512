import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import useEditPage from 'hooks/useEditPage';
const QualitycheckEditPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		id_operator: yup.string().required().label("Operatore"),
		id_amministrator: yup.string().required().label("Id Amministrator"),
		titolo: yup.string().nullable().label("Titolo"),
		description: yup.string().required().label("Descrizione")
	});
	// form default values
	const formDefaultValues = {
		id_operator: '', 
		id_amministrator: auth.userId, 
		titolo: "NULL", 
		description: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/qualitycheck`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="QualitycheckEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Operatore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/id_operator_option_list_4"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="id_operator"     optionLabel="label" optionValue="value" value={formik.values.id_operator} onChange={formik.handleChange} options={response} label="Operatore"  placeholder="Seleziona un valore ..." filter={true} className={inputClassName(formik?.errors?.id_operator)}   />
                                                    <ErrorMessage name="id_operator" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <input name="id_amministrator"  value={formik.values.id_amministrator} type="hidden" />
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Titolo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="titolo"  onChange={formik.handleChange}  value={formik.values.titolo}   label="Titolo" type="text" placeholder="accedere Titolo"        className={inputClassName(formik?.errors?.titolo)} />
                                                <ErrorMessage name="titolo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Descrizione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="description" onTextChange={(e) => formik.setFieldValue('description', e.htmlValue)} label="Descrizione"  placeholder="accedere Descrizione" value={formik.values.description} style={{height: '400px'}} className={inputClassName(formik?.errors?.description)} />
                                                </div>
                                                <ErrorMessage name="description" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Update" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
QualitycheckEditPage.defaultProps = {
	primaryKey: 'id_quality_check',
	pageName: 'qualitycheck',
	apiPath: 'qualitycheck/edit',
	routeName: 'qualitycheckedit',
	submitButtonLabel: "Update",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record updated successfully",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default QualitycheckEditPage;
